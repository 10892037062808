#id {
  font-family: "Calibri";
}

#logo {
  width: 50px;
  height: 50px;
}

.bg-color {
  background: linear-gradient(45deg, #f5d2b4, #d4d6c9);
}

@media (max-width: 768px) {
  .transparent {
    background: linear-gradient(45deg, #f5d2b4, #d4d6c9);
  }
  #cont {
    display: flex;
    flex-direction: row-reverse;
  }
}
