*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#hi-header {
  font-family: "Calibri";
  font-size: 40px;
  color: #4d5c59;
  margin-left: 47%;
}

body {
  background: linear-gradient(120deg, #d4d6c9, #f5d2b4);
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 500px;
  background: linear-gradient(120deg, #d4d6c9, #f5d2b4);
  margin: 100px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: #4d5c59;
  color: #ff9474;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background: #4d5c59;
}
.content {
  background: #4d5c59;
  color: #ff9474;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

#pics {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

#lips {
  width: 10%;
  height: 10%;
  margin: 0.5%;
}

#lips-title {
  font-family: "Calibri";
  font-size: larger;
  font-weight: bold;
}

@media (max-width: 768px) {
  #hi-header {
    font-size: 18px;
    margin-left: 40%;
  }

  #pics {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 2% 2%;
  }
  #lips {
    width: 22%;
    height: auto;
  }
}
