#drs-lot {
  width: 30%;
  height: auto;
  margin-left: 35%;
}

#team-header {
  font-family: "Calibri";
  font-size: 40px;
  color: #4d5c59;
  margin-left: 47%;
}

#hr {
  width: 200px;
  border: 10px solid #4d5c59;
  border-radius: 5px;
  margin-left: 45.7%;
}

.owners {
  display: flex;
  flex-direction: row;
  flex-flow: row;
}

#shady,
#ghassan,
#nabil {
  width: 20%;
  margin-left: 10%;
  background: #4d5c59;
  color: #ff9474;
  border-radius: 5px;
}

@media (max-width: 768px) {
  #drs-lot {
    width: 70%;
    height: auto;
    margin-left: 20%;
  }
  #team-header {
    font-size: 18px;
    margin-left: 40%;
  }
  #hr {
    margin-left: 39%;
    width: 22%;
    border: 5px solid #4d5c59;
  }
  #card-title {
    font-size: 14px;
  }
  .owners {
    display: inline;
  }
  #shady,
  #ghassan,
  #nabil {
    width: 97%;
    margin-left: 1.5%;
    background: #4d5c59;
    color: #ff9474;
    border-radius: 5px;
  }
}
