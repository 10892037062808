/* d4d6c9,f5d2b4, eab08b, ff9474, 8faaa5 */

.HomeContainer {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #d4d6c9, #f5d2b4);
}

#header {
  font-family: "Calibri";
  font-size: 40px;
  color: #4d5c59;
}

#text {
  font-size: 30px;
  margin-right: 300px;
  font-family: "Calibri";
  color: #6c817e;
}

.section1 {
  display: flex;
  flex-direction: row;
  flex-wrap: row;
}

#whyAyoub {
  margin-left: 25px;
}

#face {
  width: 25%;
}

.section2 {
  display: flex;
  flex-direction: row;
  flex-wrap: row;
}

#ourMission {
  margin-left: 50px;
}

#clinic {
  width: 3000px;
  height: 600px;
  margin-left: 100px;
}

@media (max-width: 768px) {
  .HomeContainer {
    width: 100%;
    height: 100%;
  }

  #face {
    width: 60%;
    height: auto;
    margin-left: 20%;
  }

  .section1 {
    display: flex;
    flex-direction: column-reverse;
  }

  #whyAyoub {
    margin-top: 10%;
    margin-left: 3%;
  }
  #header {
    width: 100%;
    font-size: 18px;
  }
  #text {
    width: 100%;
    font-size: 11px;
  }
  .section2 {
    display: flex;
    flex-direction: column;
  }

  #clinic {
    width: 90%;
    height: auto;
    margin-left: 3%;
  }

  #ourMission {
    margin-left: 3%;
  }
}
