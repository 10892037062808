#footer {
  background: linear-gradient(45deg, #f5d2b4, #d4d6c9);
}

#wp,
#fb,
#inst {
  width: 3%;
  height: 3%;
  margin-left: 10%;
}

#wp {
  margin-left: 35%;
}

#ayoubLogo {
  margin-left: 47.5%;
  width: auto;
  height: 100px;
}

#copyright {
  margin-left: 45%;
  font-family: "Calibri";
  color: #4d5c59;
}

@media (max-width: 768px) {
  #wp,
  #fb,
  #inst {
    width: 10%;
    height: auto;
    margin-left: 10%;
  }
  #wp {
    margin-left: 25%;
  }
  #ayoubLogo {
    margin-left: 42%;
    width: 20%;
    height: auto;
  }
  #copyright {
    margin-left: 28%;
  }
}
